<template>
  <v-container fluid class="pt-0">
    <v-row dense>
      <v-col class="text-right" cols="12" v-if="rankings.length > 1">
        <router-link :to="{name: 'rankings-home'}" class="grey--text clickable">Looking for other rankings?</router-link>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-toolbar color="color1 color1Text--text" dark>
            <v-toolbar-title tabs>{{pointSystem ? pointSystem.name : ''}} Current Rankings</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              :slot="$vuetify.breakpoint.smAndDown ? 'extension' : null"
              :class="{ 'mb-3': $vuetify.breakpoint.smAndDown }"
              color="color1Text--text"
              append-icon="fas fa-search"
              label="Search"
              hide-details
              v-model="search"
              solo-inverted
              :light="!color1IsDark"
              clearable
            ></v-text-field>
            <v-fab-transition>
              <v-btn color="color1Text" text icon @click.stop="filter = !filter" v-if="availableTags.length > 0 && !preCalc">
                <v-icon>fas fa-filter</v-icon>
              </v-btn>
            </v-fab-transition>
          </v-toolbar>
          <v-expand-transition>
            <v-card-text v-if="id === 33">
                <div>USAVP Ranking will be based on an athlete's best three (3) results (domestic or international) within the eligible window.</div>
                <ul>
                  <li>For Junior and Amateur players (non-Pro players), the eligible window is defined as the last 365 days.</li>
                  <li>For Pro players, the eligible window is defined as the athlete's previous four (4) most recent 2023 PRO SERIES, 2023 GOLD SERIES and 2024 HERITAGE events played in the last 365 days.
                    <ul>
                      <li>A MAXIMUM of 2 INTERNATIONAL results can be used for player Ranking.</li>
                    </ul>
                  </li>
                </ul>
            </v-card-text>
          </v-expand-transition>

          <v-tabs
            fixed-tabs
            v-model="activeTab"
            slider-color="color3"
            background-color="color2"
          >
            <v-tab href="#girls" ripple class="color2Text--text">
              {{pointSystem.props.mw ? 'Womens' : 'Girls'}}
            </v-tab>
            <v-tab href="#boys" ripple class="color2Text--text">
              {{pointSystem.props.mw ? 'Mens' : 'Boys'}}
            </v-tab>
          </v-tabs>
          <v-expand-transition>
            <v-container v-if="filter && !preCalc" class="py-1">
              <v-row dense>
                <v-col cols="12">
                  <v-card class="pa-2" color="grey lighten-3">
                    <v-autocomplete
                      color="color3"
                      item-color="color3"
                      label="Filter"
                      hide-details
                      :items="availableTags"
                      multiple
                      chips dense
                      deletable-chips small-chips
                      v-model="filterItems" solo
                    ></v-autocomplete>
                    <div class="d-flex">
                      <v-switch
                        v-if="pointSystem.props.adults"
                        label="Include Adults"
                        v-model="adults"
                        color="success"
                        class="ml-3 mt-1 shrink"
                        hide-details
                      ></v-switch>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-expand-transition>
          <v-expand-transition>
            <v-row dense  v-if="ads">
              <v-col cols="12" class="d-flex justify-center">
                <div class="mr-2" style="height: 75px; width: 395px;" v-for="ad in ads" :key="ad.id">
                  <ad-card :ad="ad" :flat="true"></ad-card>
                </div>
              </v-col>
            </v-row>
          </v-expand-transition>
          <v-tabs-items v-model="activeTab">
            <v-tab-item value="girls">
              <ranking-list
                v-if="activeTab === 'girls'"
                :id="id"
                :players="girls"
                :searchTerm="preCalc ? null : search"
                :loading="loading"
                :filter="filterItems"
                :adults="adults"
                :juniors="juniors"
                :preCalc="preCalc"
                ref="girlsList"
              ></ranking-list>
            </v-tab-item>
            <v-tab-item value="boys">
              <ranking-list
                v-if="activeTab !== 'girls'"
                :id="id"
                :players="boys"
                :searchTerm="preCalc ? null : search"
                :loading="loading"
                :filter="filterItems"
                :male="true"
                :preCalc="preCalc"
                ref="boysList"
              ></ranking-list>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="user && user.vbl">
      <v-btn color="success" @click.stop="onDownloadClick">download</v-btn>
    </v-row>
    <v-dialog
      v-model="dialog"
      max-width="500px"
      transition="dialog-transition"
    >
    <v-card>
      <v-toolbar color="color1 color1Text--text" class="title">
        <v-toolbar-title>
          <v-icon color="color1Text" class="mr-3">fas fa-construction</v-icon>Under Construction
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="color2 color2Text--text"
          small fab
          @click.stop="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pa-3">
        <v-alert type="warning" :value="true" prominent class="ma-2">
          We are working on getting all the results imported please be patient
        </v-alert>
      </v-card-text>
    </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import RankingList from '@/components/RankingsList.vue'
import flatten from '@/helpers/ArrayFlatten'
import { mapGetters } from 'vuex'
import Papa from 'papaparse'
import { ageDivision } from '@/classes/ProfileHelpers'
import AdCard from '@/components/Ads/AdCard.vue'
import debounce from 'lodash.debounce'

export default {
  props: ['id'],
  data () {
    return {
      loading: false,
      players: null,
      search: '',
      activeTab: 'girls',
      filter: false,
      filterItems: [],
      dialog: false,
      adults: true,
      juniors: true,
      ages: ['10U', '11U', '12U', '13U', '14U', '15U', '16U', '17U', '18U'],
      allDivisions: ['18U', '16U', '14U', '12U', '10U', 'B', 'BB', 'A', 'AA', 'AAA', 'Unrated', 'Open', '15U', '17U', '13U'],
      ads: [],
      timeoutId: null,
      preCalc: true
    }
  },
  computed: {
    ...mapGetters(['theme', 'color1IsDark', 'rankings', 'user', 'liveUpdates', 'liveUpdateStatus', 'subdomain']),
    downloadData () {
      const t = this.$refs[`${this.activeTab}List`]
      if (t && t.rows) {
        return t.rows.map(m => {
          return {
            rank: m.rank,
            name: m.name,
            emails: m.emails,
            ageDivision: m.ageDivision
          }
        })
      }
      return []
    },
    pointSystem () {
      return this.rankings.find(f => f.id === +this.id)
    },
    hasAdults () {
      return this.players && this.players.filter(p => p.ageDivision === 'Adult').length > 0
    },
    girls () {
      return (this.players && this.players.filter((p) => {
        return !p.isMale && (this.adults || this.ages.includes(p.ageDivision))
      })) || []
    },
    boys () {
      return (this.players && this.players.filter((p) => {
        return p.isMale && (this.adults || this.ages.includes(p.ageDivision))
      })) || []
    },
    availableTags () {
      var all = this.players ? [...new Set(flatten(this.players.map(m => m.tags)))].sort() : []
      if (this.pointSystem.props.noState) {
        all = all.filter(f => this.allDivisions.includes(f))
      }

      return all
    }
  },
  methods: {
    getAds () {
      console.log(`here: ${this.liveUpdateStatus}`)
      if (this.liveUpdateStatus !== 'Connected') {
        this.timeoutId = setTimeout(this.getAds, 500)
        return
      }
      clearTimeout(this.timeoutId)
      var adsFor = this.pointSystem.sb ? this.pointSystem.name.toLowerCase() : this.subdomain
      this.liveUpdates.invoke('FetchAds', adsFor)
        .then(ads => {
          this.ads = ads.map(m => JSON.parse(m)).filter(f => f.locations && f.locations.includes('rankings'))
        })
    },
    getPlayers () {
      this.loading = true
      this.$VBL.player.getNewRankings(this.id, this.search)
        .then(r => {
          this.preCalc = r.data.preCalc
          this.players = r.data.players.map(m => {
            m.ageDivision = ageDivision(m.dob, m.gradYear)
            return m
          })
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => { this.loading = false })
    },
    onDownloadClick () {
      var fileName = `${this.pointSystem ? this.pointSystem.name : ''}_Rankings_.csv`
      var data = this.downloadData
      this.createFileForDownload(data, fileName)
    },
    createFileForDownload (data, name) {
      var opt = { header: !this.avpaFormat }
      var csv = Papa.unparse(data, opt)
      var blob = new Blob([csv])
      var a = window.document.createElement('a')
      a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
      a.download = name
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
    getStates () {
      this.$http.get(`${window.location.origin}/static/json/states.json`)
        .then(r => {
          this.states = r.data.map(m => m.abbreviation)
        })
    },
    setFilter () {
      this.filter = this.pointSystem.props.showFilter
    },
    loadPlayers: debounce(function () {
      if (this.preCalc) {
        this.getPlayers()
      }
    }, 333)

  },
  watch: {
    search: 'loadPlayers',
    activeTab () {
      this.search = ''
    },
    players: function (val) {
      if (val && this.$route.query.by) {
        this.$route.query.by.split(',').forEach(tag => {
          if (this.availableTags.includes(tag)) {
            this.filterItems.push(tag)
            this.filter = true
          }
        })
      }
    },
    id: 'setFilter'
  },
  components: {
    RankingList,
    AdCard
  },
  created () {
    this.getPlayers()
  },
  mounted () {
    this.getAds()
    this.setFilter()
  }
}
</script>

<style>

</style>
